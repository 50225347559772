export const FirebaseConfig = {
	"projectId": "exicube-57c4e",
	"appId": "1:1077413918008:web:a2bff8d3bf7d38ab12fdcd",
	"databaseURL": "https://exicube-57c4e-default-rtdb.firebaseio.com",
	"storageBucket": "exicube-57c4e.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyDrSu8AvtXlMtgxgoDwM1zkUOC3H9mvxds",
	"authDomain": "exicube-57c4e.firebaseapp.com",
	"messagingSenderId": "1077413918008",
	"measurementId": "G-R3GEMW2R2Y"
};